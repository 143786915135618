export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 100
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  // {
  //   title: '报警内容',
  //   key: 'msg'
  // },
  {
    title: '离线时间',
    key: 'offlineTime'
  },
  {
    title: '恢复时间',
    key: 'recoveryTime'
  }
];
